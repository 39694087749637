import React from 'react';
import './Blog.css';

const Blog = () => {
    const blogs = [
        {
            title: "The Importance of Digital Marketing",
            excerpt: "Discover why digital marketing is crucial for modern businesses...",
            date: "May 20, 2024"
        },
        {
            title: "Top 5 SEO Strategies for 2024",
            excerpt: "Stay ahead of the competition with these essential SEO strategies...",
            date: "May 22, 2024"
        },
        {
            title: "Social Media Marketing Tips",
            excerpt: "Maximize your reach with these effective social media tips...",
            date: "May 25, 2024"
        }
    ];

    return (
        <div className="blog-section">
            <h2>Latest Blog Posts</h2>
            <div className="blog-cards">
                {blogs.map((blog, index) => (
                    <div key={index} className="blog-card">
                        <h3>{blog.title}</h3>
                        <p>{blog.excerpt}</p>
                        <p className="date">{blog.date}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Blog;
