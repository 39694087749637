// App.js

import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import Main from './components/Main/Main';
import Navbar from './components/Navbar/Navbar';
import About from './components/About/about';
import Services from './components/Services/Services';
import WhyChooseUs from './components/WhyChooseUs/WhyChooseUs';
import ContactUs from './components/Contact/ContactUs';
import Blog from './components/Blog/Blog';


function App() {
  return (


    <div className="App">
      <HashRouter>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Main} />
          <Route path="/about" component={About} />
          <Route path="/services" component={Services} />
          <Route path="/blogs" component={Blog} />
          <Route path="/whyChooseUs" component={WhyChooseUs} />
          <Route path="/contact" component={ContactUs} />
          {/* <Footer /> */}
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
