import React from 'react'
import Home from '../Home/Home'
import About from '../About/about';
import Services from '../Services/Services';
import Blog from '../Blog/Blog';
import ContactUs from '../Contact/ContactUs';
import Footer from '../Footer/Footer';
import Testimonial from '../Testimonial/Testimonial';


function Main() {
    return (
        <>
            <Home />
            <Services />
            <About />
            <Blog />
            <Testimonial />
            {/* <WhyChooseUs /> */}
            <ContactUs />
            <Footer />
        </>

    )
}

export default Main