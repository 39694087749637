import React, { useState, useEffect } from 'react';
import './Navbar.css';
import logo from '../../assets/images/flawIT_Transparent_Logo.png';
import { NavLink } from 'react-router-dom';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
      if (screenWidth < 768) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('resize', changeWidth);

    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, [screenWidth]);

  const isContactActive = (match, location) => {
    // Add your logic to determine if the Contact link should be considered active
    return location.pathname === '/contact';
  };
  const isServiceActive = (match, location) => {
    // Add your logic to determine if the Contact link should be considered active
    return location.pathname === '/services';
  };
  const isBlogActive = (match, location) => {
    // Add your logic to determine if the Contact link should be considered active
    return location.pathname === '/blogs';
  };

  return (
    <nav className={`nav ${menuOpen ? 'open' : ''}`}>
      <div className="heading">
        <img className="brandLogo" src={logo} alt="Company Logo" />
        <h4 className="brandName">flawIT</h4>
      </div>

      <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {screenWidth < 768 && toggleMenu && (<ul className={`nav-links ${menuOpen ? 'show-links' : ''}`}>
        <li>
          <NavLink exact to="/" activeClassName="active" onClick={toggleMenu}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink isActive={isServiceActive} to={{
              pathname: 'services',
              state: { previousScreen: 'navBar' }
            }} activeClassName="active" onClick={toggleMenu}>
            Services
          </NavLink>
        </li>
         <li>
          <NavLink isActive={isBlogActive} to={{
            pathname: 'blogs',
            state: { previousScreen: 'navBar' }
          }} activeClassName="active" onClick={toggleMenu}>
            Blogs
          </NavLink>
        </li>
        <li>
          <NavLink isActive={isContactActive} to={{
            pathname: 'contact',
            state: { previousScreen: 'navBar' }
          }} activeClassName="active" onClick={toggleMenu}>
            Contact
          </NavLink>
        </li>
      </ul>)}

      {screenWidth >= 768 && (
        <ul className={`nav-links`}>
          <li>
            <NavLink exact to="/" activeClassName="active">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink isActive={isServiceActive} to={{
              pathname: 'services',
              state: { previousScreen: 'navBar' }
            }} activeClassName="active">
              Services
            </NavLink>
          </li>
          <li>
          <NavLink isActive={isBlogActive} to={{
            pathname: 'blogs',
            state: { previousScreen: 'navBar' }
          }} activeClassName="active">
            Blogs
          </NavLink>
        </li>
          <li>
            <NavLink isActive={isContactActive} to={{
              pathname: 'contact',
              state: { previousScreen: 'navBar' }
            }} activeClassName="active" >
              Contact
            </NavLink>
          </li>
        </ul>
      )}
    </nav>
  );
}

export default Navbar;
