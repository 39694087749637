import React, { useState, useEffect } from 'react';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import axios from 'axios';
import { useAlert } from 'react-alert';
import './ContactUs.css';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { useHistory } from 'react-router-dom';


// Alert configuration
const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  transition: transitions.SCALE,
};

const useCustomState = (initialValue) => {
  return (initialValue === "contactMain" ? "Wanna grow your business? Contact Us!!" : initialValue === "quoteButton" ? "Please help us with following fields. We'll get back to you soon!" : "Please help us with following fields. We'll contact to you very soon!");
  ;
};

export default function ContactUs({ previousScreen = "contactMain" }) {
  const history = useHistory();
  const temp = history.location.state?.previousScreen;
  if (temp !== undefined) {
    previousScreen = temp;
  }
  const customText = useCustomState(previousScreen);
  const [myState] = useState(customText);
  const [name, setName] = useState('');
  const [emailId, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const alert = useAlert();

  useEffect(() => {
    // Access props passed through state

  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    const formData = {
      name,
      emailId,
      message,
    };

    axios
      .post('http://localhost:4000/submitform', formData)
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        showAlert('success', 'Message sent successfully!');
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        showAlert('error', 'An error occurred. Please try again.');
      });
  };

  const showAlert = (type, message) => {
    alert.show(message, {
      type,
    });
  };

  const validateForm = () => {
    setIsError(false);

    if (name.length < 2) {
      setIsError(true);
      showAlert('error', 'Name should be at least 2 characters long.');
      return false;
    }

    if (!validateEmail(emailId)) {
      setIsError(true);
      showAlert('error', 'Please enter a valid email address.');
      return false;
    }

    if (message.trim() === '') {
      setIsError(true);
      showAlert('error', 'Please enter a message.');
      return false;
    }
    setIsError(false);

    return true;
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  return (
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <>
        <div className={`contact-us ${previousScreen !== "contactMain" ? 'notMain' : ''}`}>

          <form className="contact-form" onSubmit={handleSubmit}>
          <h1>
            {myState}
          </h1>
            <div className="form-field">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Please Enter Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {isError && name.length < 2 && (
                <div className="error-message">Name should be at least 2 characters long.</div>
              )}
            </div>
            <div className="form-field">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Please Enter Your Email"
                value={emailId}
                onChange={(e) => setEmail(e.target.value)}
              />
              {isError && !validateEmail(emailId) && (
                <div className="error-message">Please enter a valid email address.</div>
              )}
            </div>
            <div className="form-field">
              <textarea
                id="message"
                name="message"
                placeholder="What would you like from us?"
                rows="4"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              {isError && message.trim() === '' && (
                <div className="error-message">Please enter a message.</div>
              )}
            </div>
            <div className="form-field">
              <button type="submit" disabled={isError}>
                Send Message
              </button>
            </div>
          </form>
        {isLoading && <LoadingIndicator />}
        </div>
      </>
    </AlertProvider>
  );
}
