

import React from 'react';
import './WhyChooseUs.css';

const reasonsToChooseUs = [
  {
    title: 'Customized Tailored Strategies',
    description: "We understand that every business is unique. That's why we take the time to understand your specific needs, challenges, and goals, and craft bespoke strategies that align with your vision.",
  },
  {
    title: 'Proven Expertise',
    description: 'Our team comprises experts in various facets of digital marketing, including SEO, Facebook Marketing and other Social Media Advertisements, Website and Web Application, and more. With a wealth of experience, we have a proven track record of delivering results that matter.',
  },
  {
    title: 'Transparent Communication',
    description: "Communication is at the heart of successful partnerships. We believe in transparency and keeping our clients informed at every step of the journey. You'll always know what we're doing, why we're doing it, and the impact it's having on your business.",
  },
];

const WhyChooseUs = () => {
  return (
    <div className="why-choose-us">
      <h1>Why Choose Us</h1>
      <div className="reasons-list">
        {reasonsToChooseUs.map((reason, index) => (
          <div key={index} className="card">
            <h2>{reason.title}</h2>
            <p>{reason.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyChooseUs;
