import React from 'react';
import "./LoadingIndicator.css"

const LoadingIndicator = () => {
  return (
    <div className="loading-indicator">
      <div className="spinner"></div>
      <p className="loading-text">Sending...</p>
    </div>
  );
};

export default LoadingIndicator;
