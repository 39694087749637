import React from 'react';
import './About.css';
import aboutImage from '../../assets/images/about.png'; // Import the image

const AboutUs = () => {
    const belief = "At FlawIT Media, we believe in the power of digital marketing to transform businesses and elevate brands to new heights. In a world that's constantly evolving, having a strong online presence is not just an option but a necessity. We are here to be your strategically in navigating the digital landscape and achieving your business goals.";
    return (
        <div className="about-us">
            <div className="image-container">
                <img src={aboutImage} alt="About Us" />
            </div>
            <div className="about-content">
                <h1>We are here to change the way you connect with your audience</h1>
                <p>
                    {belief}
                </p>
            </div>
        </div>
    );
};

export default AboutUs;
