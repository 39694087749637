import React from 'react';
import './Testimonial.css';

const Testimonial = () => {
    const testimonials = [
        {
            quote: "FlawIT Media has transformed our business with their innovative digital marketing strategies. Our online presence has never been stronger.",
            name: "Alice Brown",
            position: "CEO, Tech Solutions",
        },
        {
            quote: "The team at FlawIT Media is outstanding. Their expertise and dedication have significantly boosted our brand visibility.",
            name: "David Wilson",
            position: "Marketing Director, Creative Agency",
        },
        {
            quote: "Working with FlawIT Media has been a game-changer for us. Their insights and strategies are top-notch.",
            name: "Sarah Johnson",
            position: "Owner, Fashion Boutique",
        },
    ];

    return (
        <div className="testimonials-section">
            <h2>Testimonials</h2>
            <div className="testimonials">
                {testimonials.map((testimonial, index) => (
                    <div key={index} className="testimonial">
                        <p className="quote">"{testimonial.quote}"</p>
                        <p className="name">{testimonial.name}</p>
                        <p className="position">{testimonial.position}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Testimonial;
