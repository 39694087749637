// Import necessary libraries and styles
import React from 'react';
import './Footer.css';

// Component definition
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          {/* <img src="logo.png" alt="Company Logo" className="footer-logo" /> */}
          <p className="footer-text">
            © 2024 FlawIT Media. All rights reserved.
          </p>
        </div>
        <div className="footer-right">
          <div className="footer-social">
            <button className="social-button" onClick={() => window.location.href = "https://www.facebook.com/flawItMedia/"}>
              <i className="fab fa-facebook-f"></i>
            </button>
            <button className="social-button" onClick={() => window.location.href = "https://www.instagram.com/flawItMedia/"}>
              <i className="fab fa-instagram"></i>
            </button>
            <button className="social-button" onClick={() => window.location.href = "https://www.linkedin.com/company/flawItMedia/"}>
              <i className="fab fa-linkedin-in"></i>
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
