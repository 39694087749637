import React, { useState } from 'react';
import './Services.css';
import FacebookImage from "../../assets/images/facebook.jpg";
import WebsiteImage from "../../assets/images/website.jpg";
import SocialMediaMarketingImage from "../../assets/images/socialMediaMarketing.jpg";
import VideoEditingImage from "../../assets/images/videoEdting.jpg";
import MobileAppImage from "../../assets/images/mobileApp.jpg";

const services = [
  {
    title: 'Facebook & Instagram Ads',
    description: 'Maximize your reach and ROI with targeted and data-driven paid advertising campaigns across various platforms.',
    image: FacebookImage,
  },
  {
    title: 'Website and Landing Page',
    description: 'Create a robust online presence and build required websites or web applications.',
    image: WebsiteImage,
  },
  {
    title: 'Social Media Marketing',
    description: 'Expertise in Facebook Ads, Instagram Ads, Google Ads, and YouTube Ads to enhance your social media presence.',
    image: SocialMediaMarketingImage,
  },
  {
    title: 'Video Editing & Poster Design Services',
    description: 'Engage your audience through various platforms and build a loyal community',
    image: VideoEditingImage,
  },
  {
    title: 'Mobile Application Services\n',
    description: 'Develop robust mobile applications to establish a strong online presence.',
    image: MobileAppImage,
  },
];

const Services = ({ previousScreen = "serviceMain" }) => {
  const serviceClass = useState(previousScreen !== "serviceMain" ? "notMain" : "");
  return (
    <div className={`servicePage ${serviceClass}`}>
      <h1>Our Services</h1>
      <div className="services-list">
        {services.map((service, index) => (
          <div key={index} className="card">
            <img src={service.image} alt={service.title} className="service-image" />
            <div className="card-content">
              <h2>{service.title}</h2>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
