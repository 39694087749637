import AlertTemplate from 'react-alert-template-basic';
import React from "react";
import { render } from "react-dom";
import "./index.css";
import App from "./App";
import { transitions, positions, Provider as AlertProvider } from 'react-alert';


const root = document.getElementById("root");
const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 3000,
  offset: '30px',
  transition: transitions.SCALE
};

// Wrap your component tree with the AlertProvider
render(
  <AlertProvider template={AlertTemplate} {...options}>
  <App />
</AlertProvider>, root);


