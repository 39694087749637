import React, { useState, useEffect } from 'react';
import {  useHistory } from 'react-router-dom';
import './Home.css';

const Home = () => {
  const history = useHistory();
  const languages = [
    "Marketing Services",
    "Web Development",
    "Brand Awareness",
    "Application Development",
  ];

  const [languageIndex, setLanguageIndex] = useState(0);
  const [isAnimated, setIsAnimated] = useState(false); // Track animation state

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLanguageIndex((prevIndex) => (prevIndex + 1) % languages.length);
      setIsAnimated(false); // Reset animation state for the new text
    }, 4000);

    return () => clearInterval(intervalId);
  }, [languages.length]);

  useEffect(() => {
    const fadeAnimation = () => {
      setIsAnimated(true); // Mark as animated
    };

    if (!isAnimated) {
      fadeAnimation();
    }
  }, [languageIndex, isAnimated]);

  const handleButtonClick = () => {
    // Navigate to the "contact" route
    history.push({
      pathname: '/contact',
      state: { previousScreen: 'quoteButton',  }
  });
  };

  return (
    <div className="homePage">
      <div className="text-content">
        <div className='static'>
          <h3>
            Your trusted digital partner for
          </h3>
        </div>
          <div className='typewriter'>
            <span id="welcome-landing-header">
              {languages[languageIndex]}
            </span>
          </div>
      </div>
        <button className="quoteButton" onClick={handleButtonClick}>Get a Free Quote</button>
    </div>
  );
};

export default Home;
